import React from "react";

function Navbar() {
  return (
    <React.Fragment>
      <section>
        <div className="navbar">
          <h1>my contact</h1>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Navbar;
